import { graphql, PageProps } from 'gatsby';
import React from 'react';

import CentresSection from '../../components/CentresSection';
import { Stack } from '../../components/Core';
import Hero from '../../components/Hero';
import { HERO_TYPE } from '../../components/Hero/constants';
import Layout from '../../components/Layout';
import SEO from '../../components/SEO';
import TestimonialsSection from '../../components/TestimonialsSection';
import ThematiqueIntroSection from '../../components/ThematiqueIntroSection';
import ThematiquesSection from '../../components/ThematiquesSection/ThematiquesSection';
import { createImageWithArtDirection } from '../../utils/serializeImageForImageComponent';

type IThematiqueTemplate = PageProps<Queries.ThematiqueQuery, IPageContext>;

const ThematiqueTemplate: FCWithClassName<IThematiqueTemplate> = (props) => {
  const { data, pageContext } = props;
  const pageData = data.wpThematique;
  const thematique = pageData;
  const centresOfferingThisThematique = data.allWpCentre.nodes;
  const pageTemplateData = 'contenuThematique' in data.wpPage.template ? data.wpPage.template.contenuThematique : null;
  if (!pageData || !pageContext.defaultLanguage || !pageTemplateData)
    return null;
  const formulaireRecherche = 'formulaireRechercheCentre' in data.wpPage.template ? data.wpPage.template.formulaireRechercheCentre : null
  const contenuSectionCentres = {
    centres: centresOfferingThisThematique,
    description: pageTemplateData?.sectionCentresNew?.description ?? '',
    image: pageTemplateData?.sectionCentresNew?.image,
    searchFormLabels:
      formulaireRecherche.formulaireRechercheDeCentre,
    titre: pageTemplateData?.sectionCentresNew?.titre,
  };
  const relatedServicesSlugs = thematique.informations.services?.map(
    (service) => service?.slug
  );
  const heroImageWithArtDirection = createImageWithArtDirection({
    desktopImage: thematique.featuredImage?.node,
    mobileImage: thematique.hero.imageMobile,
  });
  return (
    <Layout
      currentLanguage={pageContext.currentLanguage}
      currentPath={pageData.link}
      menuData={pageContext.menus}
      siteSettings={pageContext.siteSettings}
      submenuItems={pageContext.submenuItems}
      translations={pageData.translations}
    >
      <SEO pageData={pageData} />
      <Stack className="space-y-gap" justify="center">
        <Hero
          type={HERO_TYPE.STATIC}
          couleurTitre={thematique.hero.couleurTitre}
          heading={thematique.hero.titre}
          tagline={thematique.hero.tagline}
          backgroundImage={heroImageWithArtDirection}
        />
        <ThematiqueIntroSection
          title={thematique.informations.titrePresentation}
          description={thematique.informations.paragraphePresentation}
          image={thematique.informations.imagePresentation}
          services={thematique.informations.services}
          ctaAllServices={'contenuHomepage' in data.homepage.template ? data.homepage.template.contenuHomepage.sectionPhilosophie.ctaAllServices[0] : null}
        />
        <ThematiquesSection
          thematiques={data.allWpThematique.nodes}
          description={pageTemplateData?.sectionThematiques?.description}
          title={pageTemplateData?.sectionThematiques?.titre}
          ctaLabel={pageTemplateData?.sectionThematiques?.buttonLabel}
        />
        <CentresSection
          centres={centresOfferingThisThematique}
          sectionData={contenuSectionCentres}
          selectedServices={relatedServicesSlugs}
        />
        <TestimonialsSection
          testimonials={thematique.informations.temoignages}
          description={pageTemplateData?.sectionTemoignages?.description}
          title={pageTemplateData?.sectionTemoignages?.titre}
        />
      </Stack>
    </Layout>
  );
};

export default ThematiqueTemplate;

export const query = graphql`
  query Thematique(
    $id: String
    $currentLanguageSlug: String
    $centresIds: [String]
  ) {
    wpThematique(id: { eq: $id }) {
      featuredImage {
        node {
          ...MediaItemFragment
          localFile {
            ...HeroImage
          }
        }
      }
      language {
        locale
        slug
      }
      link
      seo {
        ...SEOFragment
      }
      slug
      title
      translations {
        language {
          code
          locale
        }
        uri
      }
      hero {
        couleurTitre
        imageMobile {
          ...MediaItemFragment
          localFile {
            ...MobileHeroImage
          }
        }
        tagline
        titre
      }
      informations {
        couleur
        imagePresentation {
          ...MediaItemFragment
          localFile {
            childImageSharp {
              gatsbyImageData(width: 650, layout: CONSTRAINED)
            }
            publicURL
          }
        }
        paragraphePresentation
        services {
          ... on WpService {
            id
            informationsService {
              couleur
            }
            slug
            title
            uri
          }
        }
        sousTitre
        temoignages {
          ... on WpThematique_Informations_Temoignages_Temoignage {
            description
            nom
            situation
          }
        }
        titre
        titrePresentation
      }
    }
    wpPage(
      language: { slug: { eq: $currentLanguageSlug } }
      template: { templateName: { eq: "Page Thematique" } }
    ) {
      template {
        ... on WpTemplate_PageThematique {
          contenuThematique {
            fieldGroupName
            sectionCentresNew {
              description
              titre
              image {
                ...MediaItemFragment
                localFile {
                  childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                  }
                  publicURL
                }
              }
            }
            sectionTemoignages {
              description
              titre
            }
            sectionThematiques {
              description
              titre
              buttonLabel
            }
          }
          formulaireRechercheCentre {
            formulaireRechercheDeCentre {
              adresse
              centreLePlusProche
              geolocalisation
              service
              tousServices
              messageErreurGeolocalisation
              pageDeResultats {
                ... on WpPage {
                  uri
                }
              }
            }
          }
        }
      }
    }
    homepage: wpPage(
      template: { templateName: { eq: "Page D'accueil" } }
      language: { slug: { eq: $currentLanguageSlug } }
    ) {
      template {
        ...on WpTemplate_PageDaccueil {
          contenuHomepage {
            sectionPhilosophie {
              ctaAllServices {
                externalLink
                internalLink {
                  ... on WpPage {
                    id
                    uri
                  }
                }
                style
                titre
              }
            }
          }
        }
      }
    }
    allWpCentre(filter: { id: { in: $centresIds } }) {
      nodes {
        title
        id
        uri
        coordonnees {
          adresse
          telephone
          lienRdv
        }
        slug
      }
    }
    allWpThematique(
      filter: {
        id: { ne: $id }
        language: { slug: { eq: $currentLanguageSlug } }
      }
      sort: {fields: informations___displayOrder, order: ASC}
    ) {
      nodes {
        ...ThematiqueDisplayData
      }
    }
  }
`;
