import React, { useLayoutEffect, useRef } from 'react';

import { IServiceTagData } from '../../templates/single/types/Service';
import { IWordpressCTA, IWordpressImage } from '../../types/wordpress/blocks';
import { serializeImageForImageComponent } from '../../utils/serializeImageForImageComponent';
import { Box, Flex, Stack } from '../Core';
import Image from '../Image';
import ServiceTag from '../ServiceTag';
import StyledLink from '../StyledLink';
import Text from '../Text';
import { TEXT_STYLES } from '../Text/constants';
import ThemedButton from '../ThemedButton';

interface IThematiqueIntroSectionProps {
  description: string;
  image: IWordpressImage;
  services: readonly (IServiceTagData | null)[];
  title: string;
  ctaAllServices: IWordpressCTA;
}

const ThematiqueIntroSection: FCWithClassName<IThematiqueIntroSectionProps> = (
  props
) => {
  const { ctaAllServices, description, image, services, title, ...rest } =
    props;
  const imageRef = useRef(null);
  const blocRef = useRef<HTMLElement>(null);
  useLayoutEffect(() => {
    const imageWrapperHeight =
      imageRef.current?.getBoundingClientRect()?.height;
    if (blocRef.current) {
      blocRef.current.style.height = imageWrapperHeight
        ? `${imageWrapperHeight + 60}px`
        : 'auto';
    }
  }, []);
  return (
    <Box
      className="relative w-full h-auto mx-auto lg:w-11/12 xxl:w-4/5"
      {...rest}
    >
      <Stack className="relative z-10 w-full space-y-8 lg:w-3/5 lg:space-y-16">
        <Box className="relative px-6 pt-0 pb-4 bg-primaryMain lg:pb-10 lg:px10">
          <Text
            variant={TEXT_STYLES.H3}
            className="!text-30 text-center sm:text-left w-full max-w-[15em] !font-bold -translate-x-1/2 sm:transform-none left-1/2 top-[-0.5em] relative"
          >
            {title}
          </Text>
          <Text variant={TEXT_STYLES.P} className="mt-16">
            {description}
          </Text>
        </Box>
        <Flex justify="flex-start" className="flex-wrap w-full gap-3 lg:w-4/5">
          {services?.map((service) => {
            if (!service) return undefined;
            return (
              <ServiceTag
                key={service.id}
                uri={service.uri}
                title={service.title}
                color={service.informationsService.couleur}
              />
            );
          })}
        </Flex>
        <StyledLink
          key={ctaAllServices.titre}
          to={ctaAllServices.internalLink?.uri}
          className="mx-auto -translate-x-1/2"
          href={ctaAllServices.externalLink ?? undefined}
        >
          <ThemedButton variant="outline">{ctaAllServices.titre}</ThemedButton>
        </StyledLink>
      </Stack>
      <Box
        className="absolute hidden w-2/5 left-1/2 top-14 lg:block"
        ref={imageRef}
      >
        <Image
          image={serializeImageForImageComponent({
            image,
          })}
        />
      </Box>
    </Box>
  );
};

export default ThematiqueIntroSection;
